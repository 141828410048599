import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import CreateAccount from './createAccount';
import SignInWithGoogle from '../signInWithGoogle';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await login(email, password);
      navigate('/');
    } catch (e) {
      setError('Uh-oh! Looks like there\'s an issue with your credentials. Please check your username and password and try again.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className=" mt-5  container-fluid">
      <div className=" row justify-content-center ">
        <div className="rounded-tb  col-md-6 ">
          <div className=" ">
            <div className=" container text-center py-5 ">
              <div className='d-flex justify-content-center'>
                <p className='flex txt-title'>Status Now <img src="/images/Text-logo.png" alt="Status Now Logo" style={{ width: '70px', height: '70px' }} /></p>
              </div>
              <p>Simplify the process of checking in on your loved ones, friends, social circles, and work colleagues</p>
            </div>
            <div className="">
              <div className=" ">
                <div>
                  <SignInWithGoogle />
                </div>
              </div>
            </div>

            <div className="card-body ">
              <div className="relative py-3 w-100 ">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">Or continue with</span>
                </div>
              </div>

              <form onSubmit={handleSubmit} >
                <div className="mb-3 w-100">
                  <label htmlFor="email" className="fw-semibold form-label small-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    placeholder=''
                    className="form-control shadow-sm"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <div className="mb-3 w-100 ">
                  <label htmlFor="password" className=" fw-semibold form-label small-label">
                    Password
                  </label>
                  <input
                    type="password"
                    placeholder=''
                    className="form-control shadow-sm"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                  />
                </div>
                {error && <p className="text-danger text-center error">{error}</p>}
                <button type="submit" className="text-white w-100 rounded btn bg-indigo-700 fw-bold my-3">
                  Sign in
                </button>
                <div className='text-center py-2'>
                  <p className="mb-3 text-center text-sm text-gray-500">
                    Don't have an account?{' '}
                    <Link className='font-semibold leading-6 text-indigo-600 hover:text-indigo-500' to="/create-account">
                      Create a new account
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Create Account */}
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Account</h5>
                <button type="button" className="close" onClick={handleCloseModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className=" modal-body">
                <CreateAccount />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;