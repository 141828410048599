import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { MdIosShare } from "react-icons/md";

const InstallPrompt = ({ isOpen, onClose, deferredPrompt }) => {
  const [isIos, setIsIos] = useState(false); // Detect if on iOS
  const [isSafari, setIsSafari] = useState(false); // Detect if on Safari
  const [isStandalone, setIsStandalone] = useState(false); // Check if app is already installed

  useEffect(() => {
    // Detect if the user is on an iOS or Safari browser
    const userAgent = window.navigator.userAgent;
    const isIosDevice = /iPhone|iPad|iPod/i.test(userAgent);
    const isSafariBrowser = (userAgent.includes('Safari') && !userAgent.includes('Chrome')) || (isIosDevice && /Safari/i.test(userAgent));

    setIsIos(isIosDevice);
    setIsSafari(isSafariBrowser);

    // Check if app is running in standalone mode (already installed)
    const isAppStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
    setIsStandalone(isAppStandalone);

    // Only prompt if not in standalone mode, the app isn't installed, and if the user triggers the prompt
    if (!isIosDevice && !isSafariBrowser && deferredPrompt && isOpen && !isAppStandalone) {
      // Prompt needs to be triggered by a user gesture (which is passed via isOpen)
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        onClose(); // Close after handling the user choice
      });
    }
  }, [deferredPrompt, isOpen, onClose]);

  // If app is already installed or in standalone mode, do not show any prompt/dialog
  if (isStandalone) return null;

  // Show the dialog only for Safari users (iOS or desktop)
  return isSafari || isIos ? (
    <Dialog className="relative z-10 pb-5 mb-5 " open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity pb-5 mb-5" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-start sm:p-0">
          <Dialog.Panel
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
  <Dialog.Title as="h3" className="text-xl font-bold text-gray-900">
    Install Status Now App for Notifications
  </Dialog.Title>

  <div className="mt-4 space-y-4 text-left">
    <p className="text-lg font-semibold text-gray-700">Why Install the App?</p>
    <ul className="list-disc space-y-2 text-gray-600 pl-5">
      <li>Receive and send real-time notifications about status updates.</li>
      <li>Stay updated with emergency alerts and status changes.</li>
      <li>Quickly access group updates directly from your home screen.</li>
    </ul>
  </div>

  {isIos && !isStandalone && (
    <div className="mt-6 space-y-4 text-left">
      <p className="text-lg font-semibold text-gray-700">How to Install on iOS (Safari):</p>
      <ol className="list-decimal space-y-2 pl-5 text-gray-600">
        <li >
        
          <div className='flex'>
          Tap the <span className='flex pe-3 text-2xl'><MdIosShare /></span> at the bottom of your Safari app.
         
          </div>
        </li>
        <li>Scroll down and select "Add to Home Screen."</li>
        <li>Tap "Add" on the top-right corner.</li>
      </ol>
      <div className="mt-4 flex justify-center">
        <img src="/Videos/IOS Install.gif" alt="Safari macOS installation GIF" className="w-full h-auto rounded-md shadow-md" />
      </div>
    </div>
  )}

  {isSafari && !isIos && (
    <div className="mt-6 space-y-4 text-left">
      <p className="text-lg font-semibold text-gray-700">How to Install on macOS (Safari):</p>
      <ol className="list-decimal space-y-2 pl-5 text-gray-600 ">
        <li >
          <div className='flex'>
          Click the<span className='pe-3 text-2xl'><MdIosShare /></span> icon at the top-right corner of the Safari window.
          </div>
        </li>
        <li>Select "Add to Dock."</li>
        <li>Follow the on-screen prompts and click "Add" to complete the installation.</li>
      </ol>
      <div className="mt-4 flex justify-center">
        <img src="/Videos/Gif Safari Mac.gif" alt="Safari macOS installation GIF" className="w-full h-auto rounded-md shadow-md" />
      </div>
    </div>
  )}
</div>

            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  ) : null; // For non-iOS and non-Safari users, no custom dialog will be shown.
};

export default InstallPrompt;
