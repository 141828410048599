
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { CgAddR } from "react-icons/cg";

const AppendGroup = () => {
  const currentUser = localStorage.getItem('currentUserEmail');
  const groupData = JSON.parse(localStorage.getItem(`groupData-${currentUser}`));
  const user = JSON.parse(localStorage.getItem(`userData-${currentUser}`));

  if (!groupData || groupData.length === 0) {
    return (
      <div className="text-center ">
        <div className='p-4'>
      <p className='font-bold h4'>Create Your First Group</p>
      <p>Welcome to status now, <span className='font-bold'>{user.userFirstName}</span>!👋 To get started, tap the green icon below to create your first group and invite the people who matter most to join you.</p>
     
      <Link className='rounded-pill btn text-success mt-4 text-5xl' to={'/create-group'}>
                    <CgAddR />
                  </Link>
        </div>           
    </div>
    );
  }

  // Function to get the image path based on group type
  const getImagePath = (groupType) => {
    switch (groupType) {
      case 'Business':
        return '/images/Bsuiness.svg';
      case 'Church':
        return '/images/Hospital.svg';
      case 'Extended Family':
        return '/images/Family.svg';
      case 'Friends':
        return '/images/Friend.svg';
      default:
        return '/images/Neighbor.svg'; // Default image path if group type is not recognized
    }
  };



  
  return (
    <div>
      {groupData.map((group) => {
        // Log the groupType for each group
        console.log('Group Type:', group.groupType);
        
        return (
          <ul
         
          
        >
          <Link
            key={group.groupID}
            to={`/linked/${group.groupId}`}
            className='mb-5'
          >
              
              <li  className="relative flex justify-between gap-x-6 p-3 hover:bg-gray-50 sm:px-6 border-bottom border-light ">
                        <div className="flex min-w-0 gap-x-4 ">
                            <img
                              className=''
                              src={getImagePath(group.groupType)} // Set image path dynamically based on group type
                              alt=""
                              style={{ width: '50px', height: '50px', borderRadius: '15px' }}
                            />
                          <div className="min-w-0 flex-auto">
                            <p className="text-md font-medium leading-6 text-gray-900">
                              
                                <span className="absolute inset-x-0 -top-px bottom-0" />
                                {group.groupName}
                             
                            </p>
                            <p className="mt-1 flex text-xs font-medium leading-5 text-gray-500">
                              Code: {group.groupCode}
                              </p>
                          </div>
                        </div>
                        <div className=" flex shrink-0 items-center gap-x-4">
                          {/* <div className="hidden sm:flex sm:flex-col sm:items-end">
                            <p className="text-sm leading-6 text-gray-900">Dione</p>
                           
                              
                            
                          </div> */}
                          <ChevronRightIcon className=" h-7 w-7 flex-none text-gray-400" aria-hidden="true" />
                        </div>
                      </li>
                    

            {/* <div className="mb-4 shadow-lg p-3 rounded-card mb-3 ">
              <div className="d-flex ">
                <div className=' rounded ms-1'>
                  <img
                    className=''
                    src={getImagePath(group.groupType)} // Set image path dynamically based on group type
                    alt=""
                    style={{ width: '70px', height: '70px', borderRadius: '15px' }}
                  />
                </div>

                <div className="card-body ps-3 ms-3">
                  <p className="text-dark text-capitalize h5 fw-bold card-title py-2">{group.groupName}</p>
                  <p className="card-text text-muted"><span className='fw-bold'>Code:</span> {group.groupID}</p>
                </div>
                <div className="align-self-center ml-auto">
                  <Link
                    className="btn btn-light"
                    to={`/Group/${group.groupID}`}
                  >
                    &#8594;
                  </Link>
                </div>
              </div>
            </div> */}
          </Link>
          </ul>
        );
      })}
    </div>
  );


  
};

export default AppendGroup;
