import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import { requestForToken } from './firebase';
import { createUser as createUserAPI, fetchUserByEmail, updateUser } from './Api/apiService';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  const authHandler = async (user, additionalData = {}, isNewUser = false) => {
    const email = user.email.toLowerCase();
    try {
      console.log('Auth handler triggered for user:', email);
      console.log('Additional data:', additionalData);

      let userProfile;
      if (isNewUser) {
        userProfile = await createUserAPI(additionalData); // Create user profile in backend
        console.log('User profile created:', userProfile);
      } else {
        try {
          userProfile = await fetchUserByEmail(email); // Fetch existing user profile from backend
          console.log('User profile fetched:', userProfile);
        } catch (error) {
          if (error.response && error.response.status === 404) {
            // If user not found, create a new user profile
            userProfile = await createUserAPI(additionalData);
            console.log('User profile created:', userProfile);
          } else {
            throw error;
          }
        }
      }

      const currentToken = await requestForToken();
      if (currentToken) {
        console.log('FCM token received:', currentToken);
        await updateUser(userProfile.id, { ...userProfile, fcmtoken: currentToken });
      }

      setCurrentUser(user);
      localStorage.setItem(`userData-${email}`, JSON.stringify(userProfile));
     
    } catch (error) {
      console.error('Error handling auth:', error);
    }
  };

  const login = async (email, password) => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      await authHandler(user, { userEmail: email });
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  };

  const createUserMeth = async (email, password, additionalData = {}) => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await authHandler(user, additionalData, true); // Indicate new user creation
      return user;
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('Email already in use');
      } else {
        throw error;
      }
    }
  };

  const logout = async () => {
    try {
      const email = currentUser.email.toLowerCase();
      const response = await fetchUserByEmail(email);
      if (response) {
        await updateUser(response.id, { ...response, fcmtoken: null });
      }
      await signOut(auth);
      setCurrentUser(null);
      localStorage.removeItem(`userData-${email}`);
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  };

  const signInWithProvider = async (provider) => {
    try {
      const { user } = await signInWithPopup(auth, provider);
      const userProfile = await fetchUserByEmail(user.email);
      if (userProfile) {
        await authHandler(user, { userEmail: user.email });
      } else {
        const userData = {
          userFirstName: user.displayName?.split(' ')[0] || '',
          userLastName: user.displayName?.split(' ')[1] || '',
          userEmail: user.email,
          userStatus: 'No Response',
          statusColor: 'grey',
          profileImageURL: user.photoURL || '',
          fcmtoken: '',
          managedGroupIDs: [],
          joinedGroupIDs: [],
          groupAlert: 'Inactive',
          subscription: 'Basic',
          subscriptionPackage: 'Monthly',
          dateSubscribed: new Date().toISOString(),
          phone:'',
          title:'',
          address:''

        };
        await authHandler(user, userData, true); // Indicate new user creation
      }
      return user;
    } catch (error) {
      console.error('Error signing in with provider:', error);
      throw error;
    }
  };

  const signInWithGoogle = () => signInWithProvider(new GoogleAuthProvider().setCustomParameters({ prompt: 'select_account' }));

  const signInWithFacebook = () => signInWithProvider(new FacebookAuthProvider());

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) await authHandler(user, { userEmail: user.email });
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  const value = {
    currentUser,
    login,
    logout,
    createUserMeth,
    signInWithGoogle,
    signInWithFacebook,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};