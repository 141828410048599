import React, { useState } from 'react';
import { FaShare } from "react-icons/fa";

const ShareGroup = ({ groupId, buttonTextColor = 'text-dark' }) => {
  const [modalOpen, setModalOpen] = useState(false);

  // Function to generate the deep link URL
  const generateDeepLink = (groupId) => {
    // Construct the deep link URL with the group ID parameter using the custom URL scheme
    return `https://statusnow.app/joingroup?groupId=${groupId}`;
  };

  // Function to handle copying the link
  const copyLink = () => {
    const deepLink = generateDeepLink(groupId);

    // Copy the deep link to the clipboard
    navigator.clipboard.writeText(deepLink)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy link: ', error);
      });
  };

  // Function to handle sending the email
  const sendEmail = () => {
    const deepLink = generateDeepLink(groupId);
    const { subject, body } = composeEmail(deepLink);

    // Create the mailto link
    const emailUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client with the pre-filled subject and body
    window.location.href = emailUrl;
  };

  // Function to handle sharing via text message/SMS
  const shareViaSMS = () => {
    const deepLink = generateDeepLink(groupId);
    const shareData = {
      title: 'Join Our Group on Status Now!',
      text: `Hey! 😊\n\nCheck out this app called Status Now. It’s great for emergency preparedness and lets us check each other’s status and update everyone when we’re safe.\n\nJoin me in our group for real-time updates and helpful tips. I think you’ll really appreciate being part of this community! Click the link to join:\n${deepLink}`
  };

    // Check if navigator.share is supported
    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing via SMS is not supported on this device.');
    }
  };

  // Function to compose the email
  const composeEmail = (deepLink) => {
    // Create the email content with the deep link URL
    const subject = 'Join Our Group on Status Now!';
    const body = `Hey! 😊\n\nCheck out this app called Status Now. It’s great for emergency preparedness and lets us check each other’s status and update everyone when we’re safe.\n\nJoin me in our group for real-time updates and helpful tips. I think you’ll really appreciate being part of this community! Click the link to join:\n${deepLink}`;
    return { subject, body };
  };

  return (
    <div className="flex justify-end ">
      <button className={`text-3xl ${buttonTextColor} font-bold  rounded`} onClick={() => setModalOpen(true)}>
        <FaShare />
      </button>
      {modalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
    <div className="mx-4  bg-white rounded-lg shadow-lg p-8 max-w-md w-full relative">
      <button
        className="text-4xl absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
        onClick={() => setModalOpen(false)}
        aria-label="Close"
      >
        &times;
      </button>
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
        Share Your Group!
      </h2>
      <p className="text-gray-600 mb-6 text-center">
        Ensure everyone is prepared by sharing your group details.
      </p>
      <div className="space-y-4">
        <button
          className="w-full flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 rounded-lg transition duration-300 ease-in-out"
          onClick={sendEmail}
        >
          <i className="fas fa-envelope mr-2"></i> Send via Email
        </button>
        <button
          className="w-full flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-semibold py-3 rounded-lg transition duration-300 ease-in-out"
          onClick={shareViaSMS}
        >
          <i className="fas fa-sms mr-2"></i> Share via Text Message
        </button>
        <button
          className="w-full flex items-center justify-center bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold py-3 rounded-lg transition duration-300 ease-in-out"
          onClick={copyLink}
        >
          <i className="fas fa-link mr-2"></i> Copy Shareable Link
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default ShareGroup;
