import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGroupById, updateGroup, fetchAllUserInfo, updateUser } from '../Api/apiService';
import './swipeButton.css'; // Import CSS for styling
import UpdateStatusAdmin from '../components/statusUpdateadmin.js';

const SwipeButton = ({ show, setShow, startFetchingData, stopFetchingData, setShowRefreshButton }) => {
  const { groupId } = useParams();
  const [isSwiped, setIsSwiped] = useState(false);
  const indicatorRef = useRef(null);
  const containerRef = useRef(null);
  const [startX, setStartX] = useState(0);
  const [modalVisible, setModalVisible] = useState(false); // State to control modal visibility

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!startX) return;

    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startX;
    const containerWidth = containerRef.current.offsetWidth;
    const indicatorWidth = indicatorRef.current.offsetWidth;

    let newPosition = Math.min(Math.max(deltaX, 0), containerWidth - indicatorWidth);
    indicatorRef.current.style.left = newPosition + 'px';

    if (deltaX > 10) {
      setIsSwiped(true);
    } else {
      setIsSwiped(false);
    }
  };

  const handleTouchEnd = () => {
    if (isSwiped) {
      setIsSwiped(true);
      setShow(false); // Hide the SwipeButton component
      handleClick();
    }
    indicatorRef.current.style.left = 0;
    setStartX(0);
    setIsSwiped(false);
  };

  const handleClick = async () => {
    try {
      await updateGroupAlertStatus(groupId);
      const getStatusInfoElement = document.getElementById('getstatusInfo');
      if (getStatusInfoElement) {
        getStatusInfoElement.classList.remove('d-none');
      }
      startFetchingData();
      setShowRefreshButton(true); // Show Refresh button
      setShow(false); // Hide SwipeButton

      // Show the modal after the update is successful
      setModalVisible(true);
    } catch (error) {
      console.error('Error updating group alert status:', error);
    }
  };

  const updateGroupAlertStatus = async (groupId) => {
    // Logic for updating group alert status remains unchanged
    try {
      const groupData = await fetchGroupById(groupId);
      const updatedGroupData = { ...groupData, alert: 'Active' };
      await updateGroup(groupId, updatedGroupData);

      const userEmails = groupData.memberEmails;
      const allUserInfo = await fetchAllUserInfo();
      const userData = allUserInfo.filter(user => userEmails.includes(user.userEmail));

      for (const user of userData) {
        await updateUser(user.id, { ...user, userStatus: 'NO RESPONSE', statusColor: 'Gray' });
      }

      const currentUser = localStorage.getItem('currentUserEmail');
      let groupDataLocal = JSON.parse(localStorage.getItem(`groupData-${currentUser}`));
      let targetGroupIndex = groupDataLocal.findIndex(group => group.groupId.toString() === groupId);

      if (targetGroupIndex !== -1) {
        groupDataLocal[targetGroupIndex].alert = 'Active';
      }

      localStorage.setItem(`groupData-${currentUser}`, JSON.stringify(groupDataLocal));
    } catch (error) {
      console.error('Error updating group alert status:', error);
      throw error;
    }
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Assuming tablets have a width of 768px or less
  };

  return (
    <>
      {show && // Conditionally render based on the show prop
        (isMobileDevice() ? (
          <div
            ref={containerRef}
            className={`swipe-button-container snap-x snap-mandatory mb-5 ${isSwiped ? 'swiped' : ''}`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              ref={indicatorRef}
              className={`swipe-indicator snap-always snap-center ${isSwiped ? 'swiped' : ''}`}
            ></div>
            <div className="swipe-text ms-5">{isSwiped ? 'Release to Activate' : 'Swipe to get status'}</div>
          </div>
        ) : (
          <button className="btn bg-red-400 mb-5" onClick={handleClick}>Get Status Now</button>
        ))}

      {/* Modal */}
      {modalVisible && (
        <div className=" modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="my-5 py-5 modal-dialog">
            <div className="modal-content">
             
              <div className="modal-body">
                {/* Content from UpdateStatusAdmin */}
                <UpdateStatusAdmin closeModal={() => setModalVisible(false)} />

              </div>
             
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SwipeButton;
