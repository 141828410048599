import React, { useEffect, useState, useRef } from 'react';
import { IoMdMore } from 'react-icons/io';
import { fetchAllUserInfo } from '../Api/apiService';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaSms } from 'react-icons/fa';

const IndividualUser = ({ userList }) => {
  const [userListData, setUserListData] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const usersData = allUserInfo.filter(user => userList.includes(user.userEmail));
        setUserListData(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [userList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  return (
    <div>
      {userListData.length > 0 ? (
        userListData.map((user) => (
          <div key={user.userEmail} className="container-fluid pb-4">
            <div className="rounded-users pe-3">
              <div className="flex">
                <div className="ml-2 mr-4">
                  <button
                    className="rounded-full flex items-center justify-center shadow"
                    onClick={() => alert('Working for now')}
                  >
                    {user.profileImageURL ? (
                      <img
                        src={user.profileImageURL}
                        alt="User Avatar"
                        className="rounded-full"
                        style={{
                          width: '50px',
                          height: '50px',
                          border: '4px solid',
                          borderColor: user.statusColor || 'green',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <span
                        className="rounded-full flex items-center justify-center"
                        style={{
                          width: '50px',
                          height: '50px',
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#000000',
                          border: '4px solid',
                          borderColor: user.statusColor || 'green',
                        }}
                      >
                        {`${user.userFirstName.charAt(0) || ''}${user.userLastName.charAt(0) || ''}`}
                      </span>
                    )}
                  </button>
                </div>
                <div className="flex-grow ml-3">
                  <div className="font-bold text-dark">{`${user.userFirstName} ${user.userLastName}`}</div>
                  <div className="text-secondary">{user.userStatus || 'Status not available'}</div>
                  {user.groups && user.groups.map((group) => (
                    <div key={group.groupID}>
                      {group.memberRole === 'owner' && <span className="badge bg-success">Owner</span>}
                      {group.memberRole === 'admin' && <span className="badge bg-primary">Admin</span>}
                      {group.memberRole === 'member' && <span className="badge bg-secondary">Member</span>}
                      <span className="text-primary">{group.groupName}</span>
                    </div>
                  ))}
                </div>
                <div className="ml-auto relative">
                  <button
                    onClick={() => setShowOptions(user.userEmail === showOptions ? null : user.userEmail)}
                    className="text-2xl text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    <IoMdMore />
                  </button>
                  {user.userEmail === showOptions && (
                    <div
                      ref={optionsRef}
                      className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10"
                    >
                      {user.phone && (
                        <button
                          className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => window.open(`tel:${user.phone}`)}
                        >
                          <FaPhoneAlt className="inline mr-2" /> Call {user.userFirstName}
                        </button>
                      )}
                      {user.phone && (
                        <button
                          className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => window.open(`sms:${user.phone}`)}
                        >
                          <FaSms className="inline mr-2" /> Text {user.userFirstName}
                        </button>
                      )}
                      {user.userEmail && (
                        <button
                          className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => window.open(`mailto:${user.userEmail}`)}
                        >
                          <FaEnvelope className="inline mr-2" /> Email {user.userFirstName}
                        </button>
                      )}
                      {user.address && (
                        <button
                          className="block w-full text-left px-3 py-3 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(user.address)}`)}
                        >
                          <FaMapMarkerAlt className="inline mr-2" /> Get Directions {user.userFirstName}
                        </button>
                      )}
                      {!user.phone && !user.userEmail && !user.address && (
                        <div className="text-gray-600 p-4">No contact information available</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default IndividualUser;
