import React from 'react';

const GreetingMessage = ({ userFirstName, currentUserEmail }) => {
  return (
    <div className='d-flex flex-column mt-2 mb-3 flex-grow-1'>
      <div>
        <p className="text-dark fs-3 ms-4"> Hi,
          <span className="ms-2 fw-bold fs-3">
            {userFirstName || currentUserEmail}!
          </span>
        </p>
        <p className="text-dark fs-6 ms-4"> Check on those you care about...</p>
      </div>
    </div>
  );
};

export default GreetingMessage;
