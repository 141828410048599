import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createGroup, updateUser } from '../../Api/apiService';
import InstallPrompt from '../InstallPrompt ';

const CreateGroup = ({ deferredPrompt }) => {
  const currentUser = localStorage.getItem('currentUserEmail'); // Get the current user's email
  const userdata = JSON.parse(localStorage.getItem(`userData-${currentUser}`)); // Get the user's data from local storage

  const [groupType, setGroupType] = useState('');
  const [groupName, setGroupName] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [privacy, setPrivacy] = useState('Public');
  const [description, setDescription] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isInstallPromptOpen, setIsInstallPromptOpen] = useState(false); // Control install prompt modal state
  const navigate = useNavigate();
 

  const groupTypes = [
    'Business',
    'Church',
    'Extended Family',
    'Friends',
    'HOA/Neighborhood',
    'Hospital or Physician Office',
    'Religious Organization',
    'Social/Groups',
    'School Staff/Classroom',
    'Other',
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!groupType || !groupName || !groupCode) {
      setError('Please fill in all fields.');
      return;
    }

    if (!userdata) {
      setError('User data not found. Please make sure you are logged in.');
      return;
    }

    const newGroup = {
      groupName,
      adminEmails: [userdata.userEmail],
      alert: 'Not Active',
      description,
      groupType,
      lastUpdatedBy: userdata.userEmail,
      memberCount: 1,
      memberEmails: [userdata.userEmail],
      privacy,
      subGroupIDs: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      zipCode,
      ownerName: `${userdata.userFirstName} ${userdata.userLastName}`,
      ownerEmail: userdata.userEmail,
      groupCode,
    };

    try {
      const createdGroup = await createGroup(newGroup);
      if (createdGroup.groupId) {
        const updatedUser = { ...userdata, managedGroupIDs: [...userdata.managedGroupIDs, createdGroup.groupId] };
        await updateUser(userdata.id, updatedUser);

        localStorage.setItem(`userData-${currentUser}`, JSON.stringify(updatedUser));

        // Reset form fields
        setGroupName('');
        setGroupType('');
        setGroupCode('');
        setPrivacy('Public');
        setDescription('');
        setZipCode('');
        setError('');
        setSuccess('Group created successfully!');

        // Show the install prompt modal if needed
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
        if (!isStandalone) {
          setIsInstallPromptOpen(true); // Trigger the install prompt modal
        } else {
          // Navigate home directly if no install prompt is needed
          navigate('/');
        }
      } else {
        setError('An error occurred while creating the group.');
      }
    } catch (error) {
      console.error('Error creating group:', error);
      setError('An error occurred while creating the group.');
    }
  };

  // Handle the modal close and navigate home
  const handleCloseInstallPrompt = () => {
    setIsInstallPromptOpen(false);
    navigate('/'); // Navigate to home after the modal is closed
  };

  return (
    <div className="mt-5 pt-5 bg-white container mx-auto px-6">
      <h2 className="text-xl font-semibold leading-7 text-gray-900">Create a Group</h2>
      <p className="mt-1 text-sm text-gray-600">
        Please fill in the details below to create your group. Fields marked with an asterisk (*) are required.
      </p>

      <form onSubmit={handleSubmit} className="mt-6 space-y-6">
        {error && <p className="text-red-600">{error}</p>}
        {success && <p className="text-green-600">{success}</p>}

        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="groupType" className="block text-sm font-medium leading-6 text-gray-900">
                  Group Type 
                </label>
                <div className="mt-2">
                  <select
                    className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                    id="groupType"
                    value={groupType}
                    onChange={(e) => setGroupType(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select Group Type
                    </option>
                    {groupTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {groupType && (
                <>
                  <div className="sm:col-span-4">
                    <label htmlFor="groupName" className="block text-sm font-medium leading-6 text-gray-900">
                      Group Name 
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="block w-full rounded-md border-2 border-gray-300 py-2 px-3  focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        id="groupName"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        placeholder="Enter the name of your group"
                        required
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="groupCode" className="block text-sm font-medium leading-6 text-gray-900">
                      Group Code 
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        id="groupCode"
                        value={groupCode}
                        onChange={(e) => setGroupCode(e.target.value)}
                        placeholder="Enter a code that users can use to search for your group (e.g., 'ABCGroup')"
                        required
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="privacy" className="block text-sm font-medium leading-6 text-gray-900">
                      Privacy 
                    </label>
                    <div className="mt-2">
                      <select
                        className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        id="privacy"
                        value={privacy}
                        onChange={(e) => setPrivacy(e.target.value)}
                        required
                      >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                      </select>
                    </div>
                  </div>

                  {privacy === 'Public' && (
                    <div className="sm:col-span-4">
                      <label htmlFor="zipCode" className="block text-sm font-medium leading-6 text-gray-900">
                        Zip Code 
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                          id="zipCode"
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                          placeholder="Enter the primary zip code where the group is located"
                          required
                        />
                      </div>
                    </div>
                  )}

                  <div className="sm:col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                      Group Description
                    </label>
                    <div className="mt-2">
                      <textarea
                        className="block w-full rounded-md border-2 border-gray-300 py-2 px-3 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        id="description"
                        rows="3"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter a brief description of your group..."
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="pb-5 mt-6 flex items-center justify-end gap-x-6">
          <Link to="/" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </Link>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Create Group
          </button>
        </div>
      </form>

      {/* Install Prompt Modal */}
      <InstallPrompt
        deferredPrompt={deferredPrompt}
        isOpen={isInstallPromptOpen}
        onClose={handleCloseInstallPrompt} // Trigger navigation on close
      />
    </div>
  );
};

export default CreateGroup;
