import React, { useState, useEffect, useRef } from 'react';
import SwipeButton from '../components/SwipeButton';
import { Link, useParams } from 'react-router-dom';
import { fetchGroupById, updateGroup, fetchAllUserInfo, updateUser } from '../Api/apiService';
import GroupPageStatusCardWidget from '../components/GroupPageStatusCard.js';
import HomeFAB from '../components/homepageFAB.js';
import NavbarMain from '../components/NavbarMain.js';
import ShareGroup from '../components/shareGroup.js';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import RefreshIcon from '@material-ui/icons/Refresh';

const MyGroup = () => {
  const { groupId } = useParams();
  const [groupData, setGroupData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSwipeButton, setShowSwipeButton] = useState(true);
  const [showRefreshButton, setShowRefreshButton] = useState(false); // State for Refresh button
  const [showGetStatusInfo, setShowGetStatusInfo] = useState(false); // State for getstatusInfo element
  const [userListData, setUserListData] = useState([]);
  const intervalRef = useRef(null);

  const fetchGroupData = async () => {
    try {
      const groupData = await fetchGroupById(groupId);
      if (groupData) {
        setGroupData(groupData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching group data:', error);
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    if (groupData && groupData.memberEmails) {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const userData = allUserInfo.filter(user => groupData.memberEmails.includes(user.userEmail));
        setUserListData(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  useEffect(() => {
    fetchGroupData();
  }, [groupId]);

  useEffect(() => {
    fetchUserData();
  }, [groupData]);

  useEffect(() => {
    const status = localStorage.getItem(`fetchStatus-${groupId}`);
    if (status === 'Active') {
      startFetchingData();
      setShowRefreshButton(true);
      setShowSwipeButton(false);
      setShowGetStatusInfo(true);
    }
  }, [groupId]);

  const startFetchingData = () => {
    if (intervalRef.current) return;

    setShowGetStatusInfo(true);

    intervalRef.current = setInterval(() => {
      fetchGroupData();
      fetchUserData();
    }, 10000);

    localStorage.setItem(`fetchStatus-${groupId}`, 'Active');
  };

  const stopFetchingData = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    setShowGetStatusInfo(false);

    localStorage.removeItem(`fetchStatus-${groupId}`);
  };

  const handleEndRequest = async (groupId) => {
    try {
      // Fetch the current group data
      const groupData = await fetchGroupById(groupId);

      // Update only the alert status
      const updatedGroupData = { ...groupData, alert: 'Inactive' };

      // Update the group in the backend
      await updateGroup(groupId, updatedGroupData);

      // Update all members' status to 'Safe' and statusColor to 'Green'
      const userEmails = groupData.memberEmails;
      const allUserInfo = await fetchAllUserInfo();
      const userData = allUserInfo.filter(user => userEmails.includes(user.userEmail));

      for (const user of userData) {
        await updateUser(user.id, { ...user, userStatus: 'SAFE', statusColor: 'Green' });
      }

      // Update local storage
      const currentUser = localStorage.getItem('currentUserEmail');
      let groupDataLocal = JSON.parse(localStorage.getItem(`groupData-${currentUser}`));

      let targetGroupIndex = groupDataLocal.findIndex(group => group.groupId.toString() === groupId);

      if (targetGroupIndex !== -1) {
        groupDataLocal[targetGroupIndex].alert = 'Inactive';
      }

      localStorage.setItem(`groupData-${currentUser}`, JSON.stringify(groupDataLocal));

      console.log(`Alert status updated for group ${groupId}. New status: Inactive`);

      // Stop fetching data, hide refresh button, show SwipeButton, hide getStatusInfo element
      stopFetchingData();
      setShowRefreshButton(false); // Hide Refresh button
      setShowSwipeButton(true); // Show SwipeButton
    } catch (error) {
      console.error('Error updating group alert status:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!groupData) {
    return <div>Group not found</div>;
  }

  return (
    <>
      <main className=' bg-white'>
        <div className=" navbarCst px-4 pb-4 pt-5 shadow lg:rounded-t-lg">
          <div className=''>
            <Link className="shadow bg-white mb-5 mt-3 text-2lg font-bold btn" to={`/Linked/${groupId}`}>
              <NavigateBeforeIcon /> Main
            </Link>
          </div>
        </div>
        <div className="pt-3 px-4 bg-white text-center">
          <h1 className="text-3xl font-bold text-dark">{groupData.groupName}</h1>
          <p className="text-dark">{groupData.description}</p>
        </div>
        <div className="ml-auto me-4">
          <ShareGroup groupId={groupId} buttonTextColor="text-dark" />
        </div>
        <div id='swipeBtn' className='d-flex justify-content-center swipeBtn'>
          <SwipeButton 
            show={showSwipeButton} 
            setShow={setShowSwipeButton} 
            startFetchingData={startFetchingData}
            stopFetchingData={stopFetchingData}
            setShowRefreshButton={setShowRefreshButton} // Pass state setter for Refresh button
          />
        </div>
        {/* {showRefreshButton && (
          <div className='pb-4 my-4 d-flex justify-content-center'>
            <button className="btn btn-dark" onClick={() => {
                fetchUserData();
                startFetchingData();
                }}>
                 <RefreshIcon /> Refresh Data
              </button>
          </div>
        )} */}
        <main>
          <section>
            <GroupPageStatusCardWidget 
              groupId={groupId} 
              stopFetchingData={stopFetchingData} 
              setShowRefreshButton={setShowRefreshButton} 
              setShowSwipeButton={setShowSwipeButton} 
              userListData={userListData} 
              setUserListData={setUserListData} 
              showGetStatusInfo={showGetStatusInfo} // Pass state to control visibility
            />
          </section>
        </main>
      </main>
    </>
  );
};

export default MyGroup;
